<template>
  <IowaRaw msg="News After The Cameras Turn Off"/>
</template>

<script>
import IowaRaw from './components/IowaRaw.vue'
import axios from 'axios';

export default {
  name: 'App',
  components: {
    IowaRaw
  },
  mounted(){
    axios
        .get("./api/v1/roles")
        .then(response => {
            console.warn("RESPONSE, response")
            this.users = response.data
        }
        )
  },
  methods: {
    getRoles: function () {
      axios
          .get("/api/v1/roles")
          .then(response => (this.roles = response.data))
    },
    postRole: function () {
      // Creating
      if (this.role_id == '' || this.role_id == null) {
        axios
            .post("/api/v1/roles", {
              "name": this.role_name,
            })
            .then(savedRole => {
              this.roles.push(savedRole.data);
              this.role_name = '';
              this.role_id = '';
            })
      } else { // Updating
        axios
            .post("/api/v1/roles", {
              "id": this.role_id,
              "name": this.role_name,
            })
            .then(() => {
              this.getRoles();
              this.role_name = '';
              this.role_id = '';
            })
      }
    },
  }
}

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
