<template>
    <div>
        <div>
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h1>About Iowa Raw</h1>
                                <p>Currently IowaRaw is strictly volunteer and no money is actually made by IowaRaw. In fact IowaRaw's current operating expense is currently around -$4,000. IowaRaw exists simply to provide a source of information that is deliberately being omitted by the mainstream media. When the network cameras shut off, is when IowaRaw turns on. IowaRaw places value on truth, honesty, accountability, and showing what is not being shown on mainstream media. IowaRaw will not be bought out and will not bend a knee to the billionaires that own/dictate almost every news venue in the US and push false narratives to fit their personal agendas.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
// import moment from 'moment';

export default {
  name: 'About',
  components: {},
  props: {
        mobile: Boolean,
  },
//   data () {},
    computed: {
    },
    methods: 
    {
        openLink: function(link) 
        {
            window.open(link);
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
