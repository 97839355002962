<template>
  <div class="container">
    <img class="logo" alt="IOWA RAW" src="../assets/IowaRawLogoOneLine.png" style="width: 100% !important;">
    <img v-if="this.screenSize < 1000" class="logo" alt="NEWS AFTER THE CAMERAS TURN OFF" src="../assets/NewsAfterTheCamerasTurnOffStacked.png" alt-text="NEWS AFTER THE CAMERAS TURN OFF" style="padding:5px 10px 5px 0px; width: 100% !important;">
    <img v-if="this.screenSize >= 1000" class="logo" alt="NEWS AFTER THE CAMERAS TURN OFF" src="../assets/NewsAfterTheCamerasTurnOffOneLine.png" alt-text="NEWS AFTER THE CAMERAS TURN OFF" style="padding:5px 0px 5px 0px; width: 100% !important;">
    <img src="../assets/Bar.jpg" class="divider-bar">
  </div>
</template>

<script>

export default {
  name: 'Header',
  components: {
  },
props: {
  screenSize: Number,
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
