<template>
    <div>
        <div>
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h1>Other News Sites</h1>
                                <div style="text-align: left;">
                                    <div>
                                        <a href="https://theiowastandard.com/" target="_blank">The Iowa Standard</a>: 
                                        <p>As quoted on their website The Iowa Standard says "At “The Iowa Standard,” we don’t have a Republican standard or a Democrat standard. Our standard is truth.\n\nWe aim to provide you with a service that does not exist — a full-time conservative news website covering all political things in Iowa."</p>
                                    </div>
                                    <div>
                                        <a href="https://www.theepochtimes.com/" target="_blank">The Epoch Times</a>: 
                                        <p>As quoted on their website "The Epoch Times is the fastest-growing independent news media in America. We are nonpartisan and dedicated to truthful reporting.
                                            <br><br>We are free from the influence of any government, corporation, or political party—this is what makes us different from other media organizations. Our goal is to bring our readers accurate information so they can form their own opinions about the most significant topics of our time."
                                        </p>
                                    </div>
                                    <div>
                                    <a href="https://www.projectveritas.com/" target="_blank">Project Veritas</a>: 
                                        <p>As quoted on their website "James O’Keefe established Project Veritas in 2011 as a non-profit journalism enterprise to continue his undercover reporting work. Today, Project Veritas investigates and exposes corruption, dishonesty, self-dealing, waste, fraud, and other misconduct in both public and private institutions to achieve a more ethical and transparent society."</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
// import moment from 'moment';

export default {
  name: 'OtherNews',
  components: {},
  props: {
        mobile: Boolean,
  },
//   data () {},
    computed: {
    },
    methods: 
    {
        openLink: function(link) 
        {
            window.open(link);
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
